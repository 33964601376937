import {getAction} from "@/utils/request";

/**
 * 获取讲课专家信息
 * @returns {*}
 */
export function getSpeakerList() {
  return getAction("/api/sysprogram/getListWithSpeakInfoByAgendaId", { agendaId: '1387140317249536'})
}

/**
 * 获取邀请专家信息
 * home
 * @returns {*}
 */
export function getExpertsByGroupId(params) {
  return getAction("/api/sysprogram/getExpertsByGroupId", params)
}

