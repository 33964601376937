<template>
  <div class="invited-guests-container">
    <header-poster v-if="!screenWidthIsLe768()">
      <div class="header-poster-content">
        <div>PROGRAMS</div>
        <div>Guest Faculty</div>
      </div>
    </header-poster>
    <div class="invited-guests-content">
      <div class="title text-3xl mt-5 py-2 mb-3">
        Guest Faculty
      </div>
      <div class="desc dK:text-right font-medium dk:text-base mb-2.5">
        * Guest Faculty listed in Alphabetical order of their name.
      </div>
      <div class="search-input mb-4">
        <a-input-search placeholder="Search (Name, Affiliation, Country, Role)" size="large" allow-clear enter-button @search="onSearch" />
      </div>
      <a-row class="guest-list" :gutter="[{ xs: 10, sm: 20, md: 10, lg: 10 }, 30]">
        <a-col :sm="24" :xs="24" :md="12" :lg="8" :xl="8" :xxl="8" v-for="(item, index) in guestList" :key="index" style="height:182px;overflow: hidden;">
          <div class="guest-item p-2.5 shadow-lg" @click="showGuestMeetingInfo()">
<!--            <div class="download">-->
<!--              <a-icon class="icon" type="file-pdf" />-->
<!--              cv-->
<!--            </div>-->
            <div class="flex">
              <img class="avatar mr-3.5" :src="item.avatar" />
              <div>
                <div class="guest-name mt-5 mb-1.5 text-2xl" v-html="resolveMarkText(item.realname)"></div>
                <div class="office mb:mb-0.5" style="height:40px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;"><span v-html="resolveMarkText(item.site)"></span><!--<span v-if="item.site&&item.countryNameEn">,</span> <span v-html="resolveMarkText(getCountryName(item))"></span>--></div>
                <!-- <div class="role">
                  <span style="background: #dc3545">Moderator</span>
                  <span style="background: #007bff">Panel</span>
                  <span style="background: #6c757d">Speaker</span>
                </div> -->
                <div class="role">
                  <span class="btn" v-for="(role,i) in item.speakerRoleList" :key="i">
                    {{ role.titleEn }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal
      dialogClass="guest-meeting-info"
      v-model="visible"
      :width="modalWidth"
      :ok-button-props="{ style: { display: 'none' } }"
      cancelText="Close"
    >
      <template slot="title">
        <div class="name text-xl">
          Iki Adachi
        </div>
        <div class="office">
          Texas Children's Hospital, USA
        </div>
      </template>
      <div class="period-list">
        <div v-for="i in 6" :key="i" class="period-item">
          <div class="period-info">
            <div class="time">
              <div class="font-semibold">May 31 (Wed)</div>
              <div class="font-semibold">13:30 - 15:00</div>
            </div>
            <div class="title">
              <div class="font-semibold">AATS PG - Pediatric 2</div>
              <div class="font-semibold">Conotruncal Anomalies</div>
            </div>
            <div class="room-info">
              <div class="font-semibold">Session Room 3</div>
              <div class="font-semibold">201-202 + 203-204</div>
            </div>
          </div>
          <div class="speaker-list">
            <div class="moderator">
              <div class="font-semibold">Moderator(s)</div>
              <div class="font-semibold">
                <div>Yasuhiro Kotani (Japan)</div>
                <div>Christopher Caldarone (USA)</div>
              </div>
            </div>
            <div class="speaker">
              <div class="speaker-title">
                Double root translocation and nicaidoh procedures
              </div>
              <div class="speakers">
                <div>
                  Iki Adachi (USA)
                </div>
              </div>
            </div>
            <div class="speaker">
              <div class="speaker-title">
                Valve sparing tetrology of fallot repair
              </div>
              <div class="speakers">
                <div>
                  Emile Bacha (USA)
                </div>
              </div>
            </div>
            <div class="speaker">
              <div class="speaker-title">
                Tetrology of fallot with pulmonary atresia
              </div>
              <div class="speakers">
                <div>
                  Kisaburo Sakamoto (Japan)
                </div>
              </div>
            </div>
            <div class="speaker">
              <div class="speaker-title">
                Truncal valve insufficiency
              </div>
              <div class="speakers">
                <div>
                  Igor Konstantinov (Australia)
                </div>
              </div>
            </div>
            <div class="speaker">
              <div class="speaker-title">
                Panel Discussion
              </div>
              <div class="speakers">
                <div>
                  Igor Konstantinov (Australia)
                </div>
                <div>
                  Kisaburo Sakamoto (Japan)
                </div>
                <div>
                  Emile Bacha (USA)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { screenWidthIsLe768 } from "@/utils/util";
  import { getExpertsByGroupId } from "@/api/agenda";

  export default {
    name: "InvitedGuests",
    components: {
      HeaderPoster: () => import('@/components/HeaderPoster')
    },
    data() {
      return {
        screenWidthIsLe768,
        visible: false,
        searchVal: '',
        sourceGuestList: [],
        guestList: []
      }
    },
    computed: {
      modalWidth() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          return '90%'
        } else if (window.matchMedia('(max-width: 825px)').matches) {
          return '75%'
        } else {
          return '55%'
        }
      }
    },
    created() {
      this.initData()
    },
    methods: {
      initData() {
        getExpertsByGroupId({ groupId: '1388343158202368'}).then((res) => {
          if (res.success) {
            if (res.data) {
              this.guestListSortByLastName(res.data)
            }
          }
        })
      },
      onSearch(val) {
        if (!val) {
          this.guestList = this.sourceGuestList;
        } else {
          this.guestList = this.sourceGuestList.filter((item) => {
            return item.realname.toLowerCase().includes(val.toLowerCase()) || item.site.toLowerCase().includes(val.toLowerCase()) || this.getCountryName(item).toLowerCase().includes(val.toLowerCase())
          });
        }
        this.searchVal = val;
      },
      showGuestMeetingInfo() {
        console.log('showGuestMeetingInfo')
        // this.visible = true
      },
      getCountryName(guest) {
        if (!guest || !(guest.countryNameEn)) return ''
        return guest.countryNameEn == 'United States of America' ? 'USA' : guest.countryNameEn
      },
      guestListSortByLastName(list) {
        list.sort(function(a, b) {
              var wordsA = a.realname.trim().split(" "); // 将a的realname属性按空格分割成单词数组
              var wordsB = b.realname.trim().split(" "); // 将b的realname属性按空格分割成单词数组
              var lastWordA = wordsA[wordsA.length - 1].toLowerCase(); // 获取a的最后一个单词，并转换为小写
              var lastWordB = wordsB[wordsB.length - 1].toLowerCase(); // 获取b的最后一个单词，并转换为小写
              return lastWordA.localeCompare(lastWordB); // 使用localeCompare方法比较字符串，返回排序结果
          });
        let guestList = list;

        //guestList.sort((x, y) => x.realname.localeCompare(y.realname))
        this.sourceGuestList = this.guestList = guestList
      },
      resolveMarkText(markText) {
        if (!this.searchVal) return markText;
        const markArr = this.getMarkIndexArr(markText, this.searchVal);
        return this.replaceMark(markText, this.searchVal, markArr);
      },
      getMarkIndexArr(str, mark, position) {
        if (!position) position = 0;
        if (!str || !mark) return [];
        const index = str.toLowerCase().indexOf(mark.toLowerCase(), position)
        if (index < 0) return [];
        const positionArr = [index];
        if ((index + mark.length) < str.length) {
          positionArr.push(...this.getMarkIndexArr(str, mark, index + mark.length))
        }
        return positionArr
      },
      replaceMark(str, mark, markArr = []) {
        if (!markArr || markArr.length <= 0) return str;
        let position = 0;
        let splitArr = [];
        markArr.forEach((item, index) => {
          splitArr.push(str.slice(position, item));
          position += (item - position);
          splitArr.push(`<span class="highlight">${str.slice(position, position + mark.length)}</span>`)
          position += mark.length
          if (markArr.length - 1 === index) {
            splitArr.push(str.slice(position))
          }
        });
        return splitArr.join('');
      }
    }
  }
</script>

<style lang="less" scoped>
.invited-guests-container {
  .header-poster-content {
    & > div:first-child {
      color: #F8B301;
    }
    & > div:last-child {
      color: white;
    }
  }
  @media screen and (min-width: 768px) {
    .invited-guests-content {
      padding: 0 15px 200px;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto!important;
    }
  }
  @media screen and (min-width: 820px) {
    .invited-guests-content {
      padding: 0 15px 200px;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto!important;
    }
  }
  @media screen and (min-width: 992px) {
    .invited-guests-content {
      padding: 0 15px 200px;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto!important;
    }
  }
  @media screen and (min-width: 1200px) {
    .invited-guests-content {
      padding: 0 15px 200px;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto!important;
    }
  }
  @media screen and (min-width: 1320px) {
    .invited-guests-content {
      padding: 0 15px 200px;
      max-width: 1320px;
      margin: 0 auto!important;
    }
  }
  @media screen and (max-width: 767px) {
    .invited-guests-content {
      padding: 0 10px 20px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .invited-guests-content {
    .title {
      border-bottom: solid 1px #ddd;
    }
    .desc {
      color: #dc3545!important;
    }
    .search-input {
      /deep/ .ant-input:hover {
        border-color: #19317e;
      }
      /deep/ .ant-input-search-button {
        background-color: #19317e;
        border-color: #19317e;
        &:hover {
          background-color: rgba(25,49,126, 0.8);
          border-color: rgba(25,49,126, 0.8);
        }
      }
      /deep/ .ant-input:focus {
        border-color: #19317e;
        box-shadow: 0 0 0 2px rgba(25,49,126, 0.3);
      }
    }
    .guest-list {
      margin: 0!important;
    }
    .guest-item {
      &:hover {
        background-color: rgba(254, 242, 203, 0.5);
      }
      cursor: pointer;
      position: relative;
      border: solid 1px #ccc;
      border-radius: 5px;
      /deep/ .highlight {
        background-color: #FFFF88;
      }
      .download {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #24A7F2 0%, #1B3288 100%);
        font-weight: 500;
        border-top-right-radius: 5px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 10px;
        color: #ffffff;
        font-size: 18px;
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        .icon {
          font-size: 15px;
          color: #ffffff;
          margin-right: 2px;
        }
      }
      .office {
        font-size: 15px;
        line-height: 130%;
        font-style: italic;
        min-height: 40px;
        color: #444444;
      }
      .avatar {
        border-radius: 50%!important;
        width: 100px;
        height: 100px;
        border: 1px solid #dee2e6;
        padding: 0 2px;
      }
      .role {
        span {
          padding: 0.25em 0.4em;
          color: white;
          border-radius: 0.25rem;
        }
        span + span {
          margin-left: 0.25rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.guest-meeting-info {
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-header {
    background: linear-gradient(90deg, #24A7F2 0%, #1B3288 100%);
    padding: 8px 12px;
    .name, .office {
      color: white;
    }
  }
  &.ant-modal {
    top: 50px;
  }
  .ant-modal-body {
    max-height: calc( 100vh - 220px );
    overflow-y: auto;
    padding: 1rem;
    .period-list {
      .period-item {
        margin-bottom: 20px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        .period-info {
          background: #24A5F0;
          padding: 7px 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e3e3e3;
          & > div {
            padding: 0 15px;
          }
          .time {
            color: #444444;
            font-size: 15px;
            line-height: 170%;
          }
          .title {
            flex: 1;
            font-size: 17px;
          }
          .room-info {
            text-align: right;
            & > div:first-child {
              font-size: 15px;
              line-height: 170%;
            }
            & > div:last-child {
              color: #fff;
              background-color: #6c757d;
              padding: 0 0.4em;
              font-size: 13px;
              border-radius: 0.25rem;
            }
          }
        }
        .speaker-list {
          .moderator {
            padding: 7px 0;
            display: flex;
            border-bottom: 1px solid #e3e3e3;
            align-items: center;
            font-size: 15px;
            & > div {
              padding: 0 15px;
            }
            & > div:last-child {
              flex: 1;
              color: #1B3288;
            }
          }
          .speaker {
            padding: 7px 15px;
            border-bottom: 1px solid #e3e3e3;
            font-size: 15px;
            .speaker-title {
              color: #444444;
            }
            .speakers {
              color: #1B3288;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    .ant-btn {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      &:hover {
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}
</style>
